<template>
  <footer :id="random_id" data-testid="checkout-layout-footer" :class="twMerge(props.appendClass)">
    <!-- Address & Policies -->
    <div class="bg-primary-hover">
      <div class="mx-auto max-w-screen-xl">
        <TsRow
          no-gutter
          append-class="flex-col items-center text-idle-white pb-6 md:py-2.5 md:justify-between md:flex-row"
        >
          <TsColumn>
            <div class="flex max-md:flex-col md:space-x-4 items-center">
              <div class="max-md:bg-primary max-md:w-full max-md:py-8 shrink-0">
                <NuxtLinkWithLocale
                  to="/"
                  data-testid="checkout-layout-footer-home-link"
                  class="max-w-max block mx-auto"
                >
                  <NuxtImg
                    src="/images/logos/toolstation-logo-halo.svg"
                    alt="logo"
                    data-testid="checkout-layout-footer-toolstation-logo"
                    height="54"
                    width="223"
                    class="max-md:mx-auto max-md:max-w-max"
                    loading="lazy"
                  />
                </NuxtLinkWithLocale>
              </div>
              <TsTypography
                :align="isMobile ? 'center' : 'left'"
                append-class="md:mr-4 max-md:pt-6 max-md:px-4 max-md:pb-2 leading-relaxed text-idle-white mb-0"
              >
                ©
                <NuxtLinkWithLocale
                  to="/"
                  data-testid="checkout-layout-footer-home-link-text"
                  >Toolstation</NuxtLinkWithLocale
                >
                {{ currentYear }}. Chamber of Commerce number: 63449595
                <br v-if="isMobile" />VAT number: NL8552.40.854.B01 <br /><small
                  >version: {{ config.public.version }}</small
                >
              </TsTypography>
            </div>
          </TsColumn>
          <TsColumn>
            <ul
              class="relative flex flex-wrap md:justify-end justify-center items-center shrink-0"
            >
              <li class="flex px-4 max-md:border-r-2">
                <NuxtLinkWithLocale
                  to="/privacy-policy"
                  data-testid="checkout-layout-footer-privacy-policy-link"
                >
                  <TsTypography
                    align="center"
                    append-class="text-idle-white mb-0"
                  >
                    <!--                {{ useTranslation("privacyPolicy") }}-->
                    Privacy Policy
                  </TsTypography>
                </NuxtLinkWithLocale>
              </li>
              <li class="flex px-4 sm:max-md:border-r-2">
                <NuxtLinkWithLocale
                  to="/cookie-policy"
                  data-testid="checkout-layout-footer-cookie-policy-link"
                >
                  <TsTypography append-class="text-idle-white mb-0">
                    <!--                {{ useTranslation("cookiePolicy") }}-->
                    Cookie Policy
                  </TsTypography>
                </NuxtLinkWithLocale>
              </li>
              <!--          <li class="px-4 max-md:mt-2">-->
              <!--            <NuxtLinkWithLocale-->
              <!--                to="/"-->
              <!--                :data-testid="'desktop-' + props.dataTestId + '-cookie-setting'"-->
              <!--            >-->
              <!--              <TsTypography append-class="flex items-center text-idle-white mb-0">-->
              <!--                <NuxtImg-->
              <!--                    class="size-10 mr-2"-->
              <!--                    src="/icons/toogle-cookie.svg"-->
              <!--                    alt="Toogle Cookie"-->
              <!--                />-->
              <!--                &lt;!&ndash;                {{ useTranslation("cookieSettings") }}&ndash;&gt;-->
              <!--                Cookie Policy-->
              <!--              </TsTypography>-->
              <!--            </NuxtLink>-->
              <!--          </li>-->
            </ul>
          </TsColumn>
        </TsRow>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import {twMerge} from "tailwind-merge";
import { useRandomUUID } from "../composables/useRandomUUID";
const { isMobile } = useDevice();
const config = useRuntimeConfig();

const currentYear = new Date().getFullYear();
const random_id = ref("");

onMounted(() => {
  random_id.value = useRandomUUID();
});

type Props = {
  dataTestid?: string;
  appendClass?: string;
};

const props = defineProps<Props>();
</script>
